import {registerAttr} from '../../../../j8/htm8/src/htm8/htm8';

let lang = (document.getElementsByTagName('html').item(0) as HTMLElement).getAttribute("lang");
console.log(lang);

export const setLang = (l: string) => {
    (document.getElementsByTagName('html').item(0) as HTMLElement).setAttribute("lang", l);
    console.log("NEW LANG", l);
}


registerAttr("lang-change", (d: HTMLElement) => {
    d.addEventListener("pointerdown", () => {
        lang = (lang == 'fr') ? 'en' : 'fr';
        setLang(lang);
    });
})
