import {registerAttr} from '../../../../j8/htm8/src/htm8/htm8';

registerAttr("stepjump", (d: HTMLElement) => {
    d.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        let dest = document.querySelector('[step="' + d.getAttribute("stepjump") + '"]');
        if (!dest) {
            console.error("Pas de dest");
            return;
        }
        console.log((dest as HTMLElement).offsetHeight);
        scrollTo((dest as HTMLElement), 500);
    });
});


registerAttr("menu-toggle", (d: HTMLElement) => {
    d.addEventListener("pointerdown", () => {
        let menu = (document.querySelector('div.mobile-menu') as HTMLElement);
        if (!menu) {
            return;
        }
        if (menu.classList.contains("actif")) {
            menu.style.height = "0";
        } else {
            menu.style.height = menu.scrollHeight.toString() + "px";
        }
        menu.classList.toggle("actif");
    });
});

/**
 * Fait défiler la page vers une position spécifique ou un élément avec un easing personnalisé.
 * @param target - L'élément cible ou la position Y en pixels
 * @param duration - La durée de l'animation en millisecondes (par défaut : 500ms)
 * @param easing - Une fonction d'easing personnalisée (par défaut : ease in-out quadratique)
 * @param offset - Un décalage optionnel en pixels (par défaut : 0)
 */
const scrollTo = (
    target: HTMLElement | number,
    duration: number = 500,
    easing: (t: number) => number = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
    offset: number = 0
): void => {
    const start = window.scrollY;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let targetY = typeof target === 'number'
        ? Math.min(target + offset, documentHeight - windowHeight)
        : target.getBoundingClientRect().top + window.scrollY + offset;

    targetY -= 60;
    const distanceToScroll = Math.max(0, targetY - start);

    function step() {
        const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        window.scrollTo(0, start + distanceToScroll * easing(progress));

        if (progress < 1) {
            requestAnimationFrame(step);
        } else {
            console.log("STOP");
        }
    }

    requestAnimationFrame(step);
}