let _attrDriver: Map<string, (dom: HTMLElement) => void> = new Map();
let _tagsDriver: Map<string, (dom: HTMLElement) => void> = new Map();
let _classesDriver: Map<string, (dom: HTMLElement) => void> = new Map();

export const registerTag = (tag: string, f: (dom: HTMLElement) => void): void => {
    _tagsDriver.set(tag, f);
    parcourirNoeuds(document.body);
}

export const registerAttr = (attr: string, f: (dom: HTMLElement) => void): void => {
    _attrDriver.set(attr, f);
    parcourirNoeuds(document.body);
}

export const registerClass = (cls: string, f: (dom: HTMLElement) => void): void => {
    _classesDriver.set(cls, f);
    parcourirNoeuds(document.body);
}

const initElem = (node: Node) => {
    if (!node) {
        return;
    }
    if (node.nodeType != Node.ELEMENT_NODE) {
        return;
    }
    if ((node as any).j8init) {
        return;
    }

    if (node.hasChildNodes()) {
        node.childNodes.forEach((n) => {
            initElem(n);
        });
    }

    let elem = (node as HTMLElement);
    let m: Map<string, string>;
    for (let i = 0; i < elem.attributes.length; i++) {
        let a = elem.attributes.item(i);
        if (_attrDriver.has(a.name.toLowerCase())) {
            if (!m) {
                m = new Map();
            }
            m.set(a.name, a.value);
        }
    }
    if (m) {
        m.forEach((_, k) => {
            (elem as any).j8init = true;
            _attrDriver.get(k)(elem);
        });
    }

    // Par tag
    let tag = elem.tagName.toLowerCase();
    if (_tagsDriver.has(tag)) {
        (elem as any).j8init = true;
        _tagsDriver.get(tag)(elem);
    }

    // Par classe
    elem.classList.forEach((c)=>{
       if (_classesDriver.has(c)){
           initElem(elem);
       }
    });
}

const callback: MutationCallback = (mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
                initElem(node);
            });
        }
    }
};

const observer = new MutationObserver(callback);
observer.observe(document.body, {childList: true, subtree: true});

const parcourirNoeuds = (node: Node) => {
    initElem(node);
    node.childNodes.forEach(child => parcourirNoeuds(child));
}

document.addEventListener('DOMContentLoaded', () => {
    parcourirNoeuds(document.body);
});

// observer.disconnect();
